import { FormControl, InputLabel, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { forwardRef } from "react";
import "../../assets/scss/components/SelectDropdown/SelectDropdown.scss";
import { Option } from "../../interfaces/FormStructure";
import { useTranslation } from "react-i18next";

export interface SelectDropdownType {
  name?: string;
  options: Array<Option>;
  label?: string;
  defaultValue?: string;
  onChange: (event) => void;
  placeholder?: string;
  value?: string;
  className?: string;
}

export const SelectDropdown = forwardRef(
  (
    {
      name,
      options,
      label,
      defaultValue,
      onChange,
      placeholder,
      className,
      value,
      ...rest
    }: SelectDropdownType,
    forwardedRef
  ) => {
    const classes: string[] = [];
    if (className) classes.push(className);

      const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      onChange(event.target.value);
    };

    return (
      <FormControl variant="outlined" className="Select">
        {label && <InputLabel id={name}>{label}</InputLabel>}

        <Select
          labelId={name}
          id={name}
          ref={forwardedRef}
          value={value}
          onChange={handleChange}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PopoverClasses: {
              root: "Select__Popover",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={() => <ExpandMoreIcon className="Select__Icon" />}
          className={classes.join(" ")}
          {...rest}
        >
          <li value="">{placeholder || t("input.select")}</li>
          {options?.map((option, index) => (
            <li key={option.id} value={option.id}>
              {option.label}
            </li>
          ))}
        </Select>
      </FormControl>
    );
  }
);
